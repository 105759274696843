.icon {
  background-color: #ffffff;
  border-radius: 50%;
}

.icon:hover {
  background-color: #51dc4e;
}

.modal__title{
    font-size: 1.8rem;
    font-weight: 600;
}

.close__button-modal {
  float: right;
  font-size: 24px;
  background-color: transparent;
  border: none;
  color: var(--title-color);
  cursor: pointer;
}

.report_problem {
  display: flex;
  justify-content: center;
}

.report__form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  column-gap: 1rem;
  margin-bottom: 1rem;
  height: 100%;
}

.modal__header {
  display: grid;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;
}
.close__button-modal:hover {
  color: #ef0107;
  font-size: 28px;
  transition: font-size 0.2s ease-in-out;
}

/* TEXTAREA */
.contact__form-div {
  position: relative;
  height: 4rem;
}

.contact__form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: none;
  columns: #fff;
  outline: none;
  border-radius: 0.75rem;
  padding: 1.5rem;
  z-index: 1;
}

.contact__form-tag {
  position: absolute;
  top: -0.75rem;
  left: 1.25rem;
  font-size: 0.813rem;
  padding: 0.25rem;
  background-color: #ffffff;
  z-index: 10;
}

.contact__form-area {
  height: 11rem;
}

.contact__form-area textarea {
  resize: none;
}

.issue__button {
  display: inline-block;
  background-color: rgb(48, 143, 29);
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 1rem;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  outline: none;
}

.issue__button:hover {
  background-color: rgb(63, 238, 19);
}

.button__icon {
  margin-left: 0.5rem;
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .issue__button {
      padding: 1rem 1.75rem;
    }
  
    .button__icon {
      width: 22px;
      height: 22px;
    }
  }
  
